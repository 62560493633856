import styled, { css } from 'styled-components'

import Button from '@nickel/core-ui/components/Button'
import Heading from '@nickel/core-ui/components/Heading'
import { References } from '@nickel/foundations/tokens/color'
import { Breakpoint, Spacing } from '@nickel/foundations/tokens/layout'
import { FontWeight } from '@nickel/foundations/tokens/typography'

import { HiddenDesktop, WhiteButton } from 'src/styles'

export const HeaderSearchBar = '5rem'

export const SearchButton = styled(Button)`
    ${HiddenDesktop};
    justify-content: center;
    width: 100%;

    div {
        flex-grow: unset !important;
    }
`

export const LocalisationButton = styled(WhiteButton)`
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
`

export const MobileSeparator = styled.span`
    ${HiddenDesktop};
    font-weight: bold;
`

export const SearchTitle = styled(Heading.H1)`
    text-align: center;

    @media (min-width: ${Breakpoint.MEDIUM}) {
        font-weight: ${FontWeight.BOLDER};
    }
`

export const SearchBarWrapper = styled.div<{ isMobileFullScreen: boolean }>`
    align-items: center;
    background-color: ${References.REF_NEUTRAL_N10};
    border-radius: ${Spacing.LARGE};
    display: flex;
    flex-direction: column;
    gap: ${Spacing.XXLARGE};
    height: auto;
    justify-content: center;
    padding: ${Spacing.LARGE};
    position: relative;
    width: 100%;
    z-index: 2;

    @media (min-width: ${Breakpoint.MEDIUM}) {
        height: auto;
        padding: ${Spacing.XLARGE} 0;
        width: 100%;
    }

    ${({ isMobileFullScreen }) =>
        isMobileFullScreen &&
        css`
            background-color: ${References.REF_NEUTRAL_N0};
            border-radius: 0;
            height: ${HeaderSearchBar};
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;

            ${SearchTitle} {
                display: none;
                visibility: hidden;
            }
        `}
`

export const SearchBarElementsWrapper = styled.div<{ isMobileFullScreen: boolean }>`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${Spacing.XLARGE};
    justify-content: center;
    width: 100%;

    @media (min-width: ${Breakpoint.MEDIUM}) {
        flex-direction: row-reverse;
        justify-content: center;
        width: 80%;

        ${LocalisationButton} {
            background-color: unset;
            width: fit-content;
        }
    }

    ${({ isMobileFullScreen }) =>
        isMobileFullScreen &&
        css`
            ${SearchButton}, ${LocalisationButton}, ${MobileSeparator} {
                display: none;
                visibility: hidden;
            }

            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;

            button {
                place-self: unset;
            }
        `}
`
