import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'

import { fillSearchList, getUrlToBackWithCoordinates, STORES_PAGE_SIZE } from './domain/domain'
import { useGetStore } from './hooks/useGetStore'
import { PosListWrapper, SearchMapWrapper, Wrapper } from './styles'

import { PosCard } from 'src/components/PosCard/PosCard'
import { ToasterContainer } from 'src/components/ToasterContainer/ToasterContainer'
import { AppContext } from 'src/context'
import { AppActionsType } from 'src/context/types'
import { MapWrapper } from 'src/features/MapWrapper/MapWrapper'
import { SearchBar } from 'src/features/SearchBar/SearchBar'
import { SearchResult } from 'src/features/SearchResult/SearchResult'
import { StoreList } from 'src/features/StoreList/StoreList'
import { useLangCountry } from 'src/hooks/useLangCountry'
import { useMediaQuery } from 'src/hooks/useMediaQuery'
import { HOME_ROUTES } from 'src/routes/constants'
import { useGetStores } from 'src/screens/Home/hooks/useGetStores'
import { PageResponseStores } from 'src/services/annuaire-buraliste-api-v3'
import { disableScroll, enableScroll, isMobileFullScreen, isMobileHomePage } from 'src/utils/layout/layout'
import { useTrackPageView } from 'src/utils/Tracking/useTrackPageView'

export const Home = () => {
    const isDesktop = useMediaQuery()
    const { country } = useLangCountry()
    const navigate = useNavigate()
    const { key } = useLangCountry()
    const { state, dispatch } = useContext(AppContext)

    const [stores, setStores] = useState<PageResponseStores | undefined>(undefined)
    const [searchResultOpen, setSearchResultOpen] = useState<boolean>(false)

    const { storePinned } = useGetStore(state.storePinnedId)
    const { storesRequest, isFetching } = useGetStores(country, isDesktop)
    const [searchParams] = useSearchParams()

    const _isMobileFullScreen = useMemo(() => isMobileFullScreen(isDesktop, state.mapPosition, searchParams), [
        isDesktop,
        state.mapPosition,
        searchParams
    ])

    const _isMobileHomePage = useMemo(() => isMobileHomePage(isDesktop, state.mapPosition), [
        isDesktop,
        state.mapPosition
    ])

    useTrackPageView()

    useEffect(() => {
        if (storesRequest) {
            fillSearchList(isFetching, setStores, stores, storesRequest)
            setSearchResultOpen(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storesRequest, isFetching])

    useEffect(() => {
        dispatch({ type: _isMobileFullScreen ? AppActionsType.SET_ENABLE_OVERLAY : AppActionsType.SET_DISABLE_OVERLAY })

        if (_isMobileFullScreen) {
            disableScroll()
        } else {
            enableScroll()
        }

        return () => {
            dispatch({ type: AppActionsType.SET_DISABLE_OVERLAY })
            enableScroll()
        }
    }, [_isMobileFullScreen, dispatch])

    const saveSearchAndNavigate = (storeId: string) => {
        const url = getUrlToBackWithCoordinates(searchParams)
        dispatch({
            type: AppActionsType.SET_SEARCH_RESULT,
            payload: {
                url,
                pageSize: (state.pageNumber + 1) * STORES_PAGE_SIZE
            }
        })
        dispatch({
            type: AppActionsType.SET_MAP_POSITION
        })
        navigate(`${HOME_ROUTES[key]}?store=${storeId}`)
    }

    return (
        <Wrapper data-testid="home">
            <SearchBar isMobileFullScreen={_isMobileFullScreen} />
            <SearchMapWrapper>
                {(isDesktop || _isMobileHomePage) && searchResultOpen && (
                    <PosListWrapper>
                        {isDesktop && (
                            <SearchResult
                                saveSearchAndNavigate={saveSearchAndNavigate}
                                searchStores={isFetching}
                                setSearchResultOpen={setSearchResultOpen}
                                stores={stores}
                            />
                        )}
                    </PosListWrapper>
                )}
                {_isMobileFullScreen && storePinned && (
                    <PosCard
                        isMobileFullScreen
                        saveSearchAndNavigate={saveSearchAndNavigate}
                        selected
                        store={storePinned}
                    />
                )}
                {(isDesktop || _isMobileFullScreen) && (
                    <MapWrapper
                        hasResults={stores?.content !== undefined && stores.content?.length > 0}
                        saveSearchAndNavigate={saveSearchAndNavigate}
                        searchResultOpen={searchResultOpen}
                        setSearchResultOpen={setSearchResultOpen}
                        storeLoading={isFetching}
                        storePinned={storePinned}
                    />
                )}
            </SearchMapWrapper>
            {(isDesktop || _isMobileHomePage) && <StoreList />}
            <ToasterContainer shadowRoot={document.querySelector('#widget-store-locator')?.shadowRoot} />
        </Wrapper>
    )
}
