import React from 'react'

import { useSearchParams } from 'react-router-dom'

import { Home } from '../Home/Home'
import { PosDetails } from '../PosDetails/PosDetails'

export const QueryWrapper = () => {
    const [searchParams] = useSearchParams()
    const storeId = searchParams.get('store')

    return storeId ? <PosDetails /> : <Home />
}
