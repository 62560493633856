import React, { MouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import SearchInput from '@nickel/core-ui/components/SearchInput'
import { SessionTokenContext } from '@nickel/location/sessionTokenContext'

import { enableScroll } from '../../../../utils/layout/layout'
import useAddressDetails from '../../hooks/useAddressDetails'
import useAddressPredictions from '../../hooks/useAddressPredictions'
import { HeaderMobileSearchBarInput } from '../HeaderMobileSearchBarInput/HeaderMobileSearchBarInput'
import { Localisation } from '../Localisation/Localisation'
import { MobileSuggestion } from '../MobileSuggestion/MobileSuggestion'

import { formatPredictionToOption } from './domain'
import { SearchInputWrapper } from './styles'

import { Drawer } from 'src/components/Drawer/Drawer'
import { AppContext } from 'src/context'
import { AppActionsType } from 'src/context/types'
import { useDebounce } from 'src/hooks/useDebounce'
import { useMediaQuery } from 'src/hooks/useMediaQuery'
import { AddressPredictionListItemView } from 'src/services/location-api'
import { SnowplowAction, trackClickEvent } from 'src/utils/Tracking/utils'

export const SearchBarInput = ({
    isMobileFullScreen,
    searchQuery,
    setSearching,
    setSearchQuery
}: {
    isMobileFullScreen: boolean
    searchQuery: string
    setSearching: (value: boolean) => void
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>
}) => {
    const { t } = useTranslation('home')

    const isDesktop = useMediaQuery()
    const [drawerOpen, setDrawerOpen] = useState(false)

    const [predictions, setPredictions] = useState<AddressPredictionListItemView[]>([])
    const [predictionId, setPredictionId] = useState<string | undefined>(undefined)
    const inputSearchRef = useRef<HTMLInputElement>(null)

    const { generate, token } = useContext(SessionTokenContext)

    const { dispatch } = useContext(AppContext)

    useEffect(() => {
        generate()
    }, [generate])

    const predictionDebounced = useDebounce(searchQuery, 300)
    const { predictionResults, isFetching, error } = useAddressPredictions(token, predictionDebounced)

    const { addressDetail, isFetching: addressFetching } = useAddressDetails(token, predictionId)
    const loadingDebounce = useDebounce<boolean>(isFetching || addressFetching, 300)

    const setOpenedDrawer = useCallback(
        (isOpen: boolean) => {
            setDrawerOpen(isOpen)
            if (!isMobileFullScreen && !isDesktop) {
                dispatch({ type: isOpen ? AppActionsType.SET_ENABLE_OVERLAY : AppActionsType.SET_DISABLE_OVERLAY })
            }
        },
        [isMobileFullScreen, isDesktop, setDrawerOpen, dispatch]
    )

    useEffect(() => {
        if (addressDetail && addressDetail.viewport) {
            dispatch({
                type: AppActionsType.SET_MAP_POSITION,
                payload: {
                    shouldSearch: true,
                    viewport:
                        google.maps.LatLngBounds !== undefined
                            ? new google.maps.LatLngBounds(
                                  {
                                      lat: addressDetail.viewport.southwest.lat,
                                      lng: addressDetail.viewport.southwest.lng
                                  },
                                  {
                                      lat: addressDetail.viewport.northeast.lat,
                                      lng: addressDetail.viewport.northeast.lng
                                  }
                              )
                            : undefined
                }
            })
        }
    }, [addressDetail, dispatch])

    useEffect(() => {
        if (predictionResults && !isFetching) {
            setPredictions(predictionResults)
        }
    }, [predictionResults, isFetching])

    useEffect(() => {
        if (drawerOpen && isDesktop) setOpenedDrawer(false)
    }, [drawerOpen, isDesktop, setOpenedDrawer])

    const closeDrawer = (e: MouseEvent) => {
        e.stopPropagation()
        setOpenedDrawer(false)
        if (!isMobileFullScreen) enableScroll()
    }

    const _onDesktopSelect = (id: string) => {
        trackClickEvent(SnowplowAction.SEARCH)
        setPredictionId(id)
    }

    const _onEnter = () => {
        if (predictionResults && predictionResults.length > 0) {
            trackClickEvent(SnowplowAction.SEARCH)
            setSearchQuery(predictionResults[0].label || '')
            setPredictionId(predictionResults[0].id)
        }
    }

    useEffect(() => {
        if (isFetching || addressFetching) setSearching(true)
        else setSearching(false)
    }, [isFetching, addressFetching, setSearching])

    const options = useMemo(() => formatPredictionToOption(predictions), [predictions])

    const _onMobileSelect = (id: string) => (e: MouseEvent) => {
        closeDrawer(e)
        const selectedPrediction = options.find(({ value }) => value === id)?.label || searchQuery
        setSearchQuery(selectedPrediction)
        dispatch({ type: AppActionsType.SET_ENABLE_OVERLAY })
        setPredictionId(id)
    }

    const openDrawer = () => {
        setOpenedDrawer(!isDesktop)
        setTimeout(() => inputSearchRef?.current?.focus(), 1)
    }

    return (
        <>
            {!isDesktop && (
                <HeaderMobileSearchBarInput
                    onClick={openDrawer}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                />
            )}
            {(drawerOpen || isDesktop) && (
                <SearchInputWrapper onClick={openDrawer} isActive={drawerOpen}>
                    <Drawer isActive={drawerOpen} onClose={closeDrawer} title={t('labelSearchBar')} />
                    <SearchInput
                        className="customSearchInput"
                        displaySuggestion={isDesktop && !error}
                        errorMessage={t('searchError')}
                        inError={!!error}
                        isLoading={loadingDebounce}
                        label=""
                        name="search"
                        onChange={setSearchQuery}
                        onSearchClickOrEnter={_onEnter}
                        onSelect={_onDesktopSelect}
                        options={options}
                        placeholder={t('searchPlaceholder')}
                        ref={inputSearchRef}
                        testId="searchInput"
                        value={searchQuery}
                    />
                    {!isDesktop && (
                        <Localisation
                            isMobileFullScreen={isMobileFullScreen}
                            closeDrawer={() => setOpenedDrawer(false)}
                            isResultSearch
                        />
                    )}
                    <MobileSuggestion
                        isActive={drawerOpen}
                        isFetching={isFetching}
                        onSelect={_onMobileSelect}
                        predictions={options}
                    />
                </SearchInputWrapper>
            )}
        </>
    )
}
