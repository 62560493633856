import { TFunction } from 'i18next'

import { TagState } from '@nickel/core-ui/components/Tag/types'

import { OpeningDay } from 'src/services/annuaire-buraliste-api-v3'
import {
    DEFAULT_TIMEZONE,
    formatDisplayedHours,
    getCurrentDayIndex,
    getDayOfWeekLabelTranslated,
    sortDay,
    getIfIsTimetableUnavailable
} from 'src/utils/Date'

const isOpenNow = (nowTime: string, partOpen: string, partClose: string) => {
    return partOpen <= nowTime && partClose > nowTime
}

const isOpenTodayLater = (nowTime: string, partOpen: string) => {
    return partOpen > nowTime
}

const isOpenAnotherDay = (partOpen: string) => {
    return partOpen !== undefined
}

const computeTag = (
    openingDays: OpeningDay[],
    lang: string,
    country: string,
    t: TFunction<string>,
    timeZone = DEFAULT_TIMEZONE
) => {
    const sortedDays = sortDay(openingDays)

    const nowTime = new Intl.DateTimeFormat('fr-FR', {
        timeZone,
        hour: '2-digit',
        minute: '2-digit'
    }).format(new Date())

    const currentDay = getCurrentDayIndex(timeZone)

    // For all days
    for (let i = 0; i < sortedDays.length; i += 1) {
        // Get current day in the sorted list
        const dayToSearch = (currentDay + i) % 7

        // For all timeslot in the day
        /* eslint-disable no-restricted-syntax */
        for (const element of sortedDays[dayToSearch].openingHours) {
            const partOpen = element.start
            const partClose = element.end

            if (i === 0) {
                if (isOpenNow(nowTime, partOpen, partClose)) {
                    return {
                        state: TagState.SUCCESS,
                        text: t('posCard.open', { timeClose: formatDisplayedHours(partClose, lang, country) })
                    }
                }
                if (isOpenTodayLater(nowTime, partOpen)) {
                    return {
                        state: TagState.ERROR,
                        text: t('posCard.close', { timeOpen: formatDisplayedHours(partOpen, lang, country) })
                    }
                }
            } else if (isOpenAnotherDay(partOpen)) {
                return {
                    state: TagState.ERROR,
                    text: t('posCard.openNextDay', {
                        dayOpen: getDayOfWeekLabelTranslated(sortedDays[dayToSearch].day, lang),
                        timeOpen: formatDisplayedHours(partOpen, lang, country)
                    })
                }
            }
        }
    }
    return {
        state: TagState.ERROR,
        text: t('posCard.closeWithoutInfo')
    }
}

export const getOpeningTag = (
    openingDays: OpeningDay[],
    lang: string,
    country: string,
    t: TFunction<string>,
    timeZone = DEFAULT_TIMEZONE
) => {
    if (getIfIsTimetableUnavailable(openingDays)) {
        return {
            state: TagState.INFO,
            text: t('posCard.timetableUnavailable')
        }
    }

    return computeTag(openingDays, lang, country, t, timeZone)
}
