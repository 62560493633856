import React from 'react'

import { createBrowserRouter } from 'react-router-dom'

import { QueryWrapper } from '../screens/QueryWrapper/QueryWrapper'

import { DETAILS_STORES_ROUTES, HOME_ROUTES } from './constants'
import { RedirectToHomePage } from './RedirectToHomePage'

const getHomeRoute = () =>
    Object.values(HOME_ROUTES).map((path) => ({
        element: <QueryWrapper />,
        path
    }))

const getPosDetails = () =>
    Object.values(DETAILS_STORES_ROUTES).map((path) => ({
        element: <QueryWrapper />,
        path
    }))

export const routes = [
    ...getHomeRoute(),
    ...getPosDetails(),
    {
        element: <RedirectToHomePage />,
        path: '/*'
    }
]

export const router = createBrowserRouter(routes)
