import { AppActions } from './actions'
import { AllContext, AppActionsType } from './types'

export const initialState = {
    filters: {
        onlyOpened: false
    },
    mapPosition: undefined,
    overlay: {
        hasOverlay: false
    },
    pageNumber: 0,
    searchPosition: undefined,
    searchResult: {},
    storePinnedId: undefined
}

export const mainReducer = (state: AllContext, action: AppActions) => {
    switch (action.type) {
        case AppActionsType.SET_ENABLE_OVERLAY:
            return {
                ...state,
                overlay: {
                    hasOverlay: true
                }
            }
        case AppActionsType.SET_DISABLE_OVERLAY:
            return {
                ...state,
                overlay: {
                    hasOverlay: false
                }
            }
        case AppActionsType.SET_SEARCH_RESULT:
            return {
                ...state,
                searchResult: {
                    pageSize: action.payload?.pageSize,
                    url: action.payload?.url
                }
            }
        case AppActionsType.UPDATE_FILTERS:
            return {
                ...state,
                filters: action.payload,
                pageNumber: 0
            }
        case AppActionsType.SET_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload ?? 0
            }
        case AppActionsType.SET_STORE_PINNED_ID:
            return {
                ...state,
                storePinnedId: action.payload
            }
        case AppActionsType.SET_SEARCH_POSITION_MAP:
            return {
                ...state,
                searchPosition: {
                    point: action.payload?.point,
                    viewport: action.payload?.viewport
                },
                mapPosition: {
                    shouldSearch: false,
                    point: action.payload?.point,
                    viewport: action.payload?.viewport,
                    withGeolocation: action.payload?.withGeolocation
                },
                pageNumber: 0
            }
        case AppActionsType.SET_MAP_POSITION:
            return {
                ...state,
                mapPosition: {
                    shouldSearch: action.payload?.shouldSearch,
                    point: action.payload?.point,
                    viewport: action.payload?.viewport
                }
            }
        case AppActionsType.RESET:
            return initialState
        default:
            return state
    }
}
