import React, { MouseEvent } from 'react'

import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import { Option } from '@nickel/core-ui/components/SearchInput/types'
import Text from '@nickel/core-ui/components/Text'
import { TextStyle } from '@nickel/core-ui/components/Text/types'

import { EmptyPrediction } from './EmptyPrediction'
import { ResultPrediction } from './ResultPrediction'
import { MobileSuggestionContainer, SkeletonContainer } from './styles'

import { useDebounce } from 'src/hooks/useDebounce'

export const MobileSuggestion = ({
    isActive,
    isFetching,
    onSelect,
    predictions
}: {
    isActive: boolean
    isFetching: boolean
    onSelect: (id: string) => (e: MouseEvent) => void
    predictions: Option[]
}) => {
    const { t } = useTranslation('home')

    const skeletonDebounced = useDebounce(isFetching, 150)

    return (
        <MobileSuggestionContainer isActive={isActive}>
            <Text textStyle={TextStyle.BOLD}>{t('searchResult')}</Text>
            {skeletonDebounced && (
                <SkeletonContainer>
                    <Skeleton count={20} height={40} style={{ gap: '10rem' }} />
                </SkeletonContainer>
            )}
            {!skeletonDebounced &&
                (predictions.length > 0 ? (
                    <ResultPrediction onSelect={onSelect} predictions={predictions} />
                ) : (
                    <EmptyPrediction />
                ))}
        </MobileSuggestionContainer>
    )
}
