import { OpeningDay } from 'src/services/annuaire-buraliste-api-v3'

export const DEFAULT_TIMEZONE = 'Europe/Paris'

export const getIfIsTimetableUnavailable = (openingDays?: OpeningDay[]) => {
    return !openingDays || !openingDays.length || openingDays?.every((day) => day.openingHours.length === 0)
}

export const getDayOfWeekLabelTranslated = (day: number, lang: string) => {
    // Because 01/05/2023 is Monday so easy to translate day
    const today = new Date()
    today.setUTCFullYear(2023, 4, day)
    today.setHours(0, 0)

    return today.toLocaleString(lang, {
        weekday: 'long'
    })
}

export const sortDay = (openingDays: OpeningDay[]) => {
    for (let i = 1; i < 8; i += 1) {
        if (!openingDays.some((day) => day.day === i)) {
            openingDays.splice(i - 1, 0, {
                day: i,
                openingHours: []
            })
        }
    }

    return [...openingDays].sort((openDay1, openDay2) => openDay1.day - openDay2.day)
}

export const getCurrentDayIndex = (timeZone = DEFAULT_TIMEZONE) => {
    const longEnDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const nowDate = new Intl.DateTimeFormat('en-us', {
        timeZone,
        weekday: 'long'
    }).format(new Date())

    return longEnDays.findIndex((element) => element === nowDate)
}

export const formatDisplayedHours = (hoursMinute: string, lang: string, country: string) => {
    const hoursMinuteSplit = hoursMinute.split(':')
    const today = new Date()
    today.setHours(Number(hoursMinuteSplit[0]), Number(hoursMinuteSplit[1]))

    return new Intl.DateTimeFormat(`${lang}-${country}`, {
        timeStyle: 'short'
    }).format(today)
}
