import styled from 'styled-components'

import { References } from '@nickel/foundations/tokens/color'
import { Spacing } from '@nickel/foundations/tokens/layout'

import { DisplayOnDrawer } from 'src/styles'

export const EmptyPredictionContainer = styled.div`
    align-items: center;
    color: ${References.REF_NEUTRAL_N300};
    display: flex;
    flex-direction: column;
    gap: ${Spacing.MEDIUM};
    margin-top: ${Spacing.LARGE};
    text-align: center;
`

export const MobileSuggestionContainer = styled.div<{ isActive: boolean }>`
    display: none;
    flex-direction: column;
    margin-top: ${Spacing.XXLARGE};
    ${DisplayOnDrawer}

    ${({ isActive }) => isActive && `display: flex;`}
`

export const SkeletonContainer = styled.div`
    margin-top: ${Spacing.LARGE};
    span {
        margin-top: ${Spacing.MEDIUM};
    }
`

export const ButtonPrediction = styled.button`
    align-items: center;
    appearance: none;
    background: transparent;
    border: 0;
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    padding: 0;
    text-align: left;

    svg {
        flex-shrink: 0;
    }
`

export const ListPrediction = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spacing.XLARGE};
    margin-top: ${Spacing.LARGE};
    width: 100%;
`
